import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

function NavBar() {
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const countDownDate = new Date("September 21,2023 4:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <>
      <div className="showcase">
        <Container className="h-100 mobmargin d-flex justify-content-center align-items-center">
          <div className="border clockbox">
            <div className="clockbox_box">
              <div className="countdown">
                <div className="textnumber p-5">
                  <span>{timerDays}</span>&nbsp;:&nbsp;
                  <span>{timerHours}</span>
                  &nbsp;:&nbsp;
                  <span>{timerMinutes}</span>
                  &nbsp;:&nbsp;
                  <span>{timerSeconds}</span>
                </div>
              </div>
            </div>

            <div className="mt-1 text-center">
              <a
                className="mx-2"
                href="https://www.dextools.io/app/en/ether/pair-explorer/0x36e9c865485b0487619b50dc358c1f38f2db30f3"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("../../assets/imgs/dext.svg").default}
                  alt=""
                />
              </a>
              <a
                className="mx-2"
                href="https://dexscreener.com/ethereum/0x36e9c865485b0487619b50dc358c1f38f2db30f3"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("../../assets/imgs/dexs.png").default}
                  alt=""
                />
              </a>

              <a
                className="mx-2"
                href="https://etherscan.io/address/0x03D6804aDF224804e5226A10584724D7d2149E98"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("../../assets/imgs/eth.png").default}
                  alt=""
                />
              </a>
            </div>
          </div>
        </Container>
      </div>

      <div className="showcase1 ">
        <div className="">
          <Container className="">
            <Row className=" d-flex align-items-center">
              <Col lg="8">
                <div className="text-center">
                  <img
                    className="img-fluid nftimg"
                    src={require("../../assets/imgs/aligator.png").default}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default NavBar;
