import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function NAV() {
  return (
    <div>
      <div className="lock"></div>
      <div style={{ background: "#000215" }}>
        <div className="cus-nav">
          <Container>
            <Navbar className="cus-nav" variant="dark" expand="lg">
              <Navbar.Brand
                href="#top"
                className="font-weight-bold text-uppercase"
              >
                <img
                  className="img-fluid nav-logo"
                  src={require("../../assets/imgs/textlogo.png").default}
                  alt=""
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto d-flex align-items-center">
                  <Link to="/about" className="link-style mx-3 linkh">
                    About
                  </Link>
                  <Link to="/howtobuy" className="link-style mx-3 linkh">
                    How To Buy
                  </Link>
                  <div
                    className="
                  "
                  >
                    <div className="text-right rtc">
                      <a
                        className="mx-3"
                        href="https://twitter.com/crocclock"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="img-fluid nav-logo"
                          src={require("../../assets/imgs/x.png").default}
                          alt=""
                        />
                      </a>
                      <a
                        className="mx-3"
                        href="https://www.tiktok.com/@tooncesoreilly"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="img-fluid nav-logo"
                          src={require("../../assets/imgs/tt.png").default}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>

        {/* <Container className="">
          <Row>
            <Col lg="6">
              <div className="text-left rtc">
                
              </div>
            </Col>
            <Col className="rmt" lg="6">
              <div className="text-right rtc">
               
                <a
                  className="mx-2"
                  href="https://twitter.com/crocclock"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter size={30} color="white" />
                </a>
                <a
                  className="mx-2"
                  href="https://www.tiktok.com/@tooncesoreilly"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok size={30} color="white" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
     */}
      </div>
    </div>
  );
}

export default NAV;
