import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./home.scss";

function Home() {
  return (
    <>
      <div className="commanbg d-flex align-items-center">
        <Container className="lbgcontainer">
          <div className="text-center">
            <h1 className="font-weight-bold">WHITEPAPER</h1>
          </div>
          <Row>
            <Col className="">
              <div className="">
                <h3 className="font-weight-bold textgreen">Introduction -</h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    $CROC embodies the fun and excitement of owning meme coins.
                    It’s a satirical take on the “pump and dump” that seems to
                    permeate the space. In fact $CROC is the ULTIMATE pump and
                    dump but here’s the catch… you’ll know exactly when and
                    where it’s going to happen - because it all goes down
                    according to the Countdown Crocodile Clock.
                  </p>
                  <p className="home-subtitle">
                    There’s a 2% tax that accumulates into a segregated wallet
                    and when the clock goes to zero everything in that fund will
                    go all in on $CROC. THEN the clock will reset and count down
                    to zero again - but this time the fund will sell all the
                    $CROC back into the market. Watch the clock because the
                    timing will never be the same. Could be hours, could be days
                    - have to keep your eye on the Croc Clock!
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">Tokenomics -</h3>
                <div className="mt-3">
                  <p className="home-subtitle">444,444,444,444 Supply</p>
                  <p className="home-subtitle">
                    96% immediately into the liquidity pool
                  </p>
                  <p className="home-subtitle">
                    4% held for future liquidity pools and marketing
                  </p>
                  <p className="home-subtitle">
                    2% tax segregated into the CROC CLOCK FUND
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">
                  Technology - Erc-20
                </h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    ERC-20 (Ethereum Request for Comment 20) is the gold
                    standard for creating and implementing fungible tokens on
                    the Ethereum blockchain.
                  </p>
                  <p className="home-subtitle">
                    ERC-20 tokens adhere to a set of rules and functions that
                    enable compatibility with other tokens, exchanges, wallets,
                    and decentralized applications (dApps) within the Ethereum
                    ecosystem.
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">Roadmap -</h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    Phase 1- Launch and build awareness on TikTok and Twitter
                    100+ hodlers.
                  </p>
                  <p className="home-subtitle">
                    Phase 2- Discord group, Newsletter, 1000+ hodlers
                  </p>
                  <p className="home-subtitle">
                    Phase 3- CEX listing 10,000+ hodlers
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">
                  Risks &amp; Disclaimers -
                </h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    $CROC is for entertainment purposes only. It’s designed to
                    be an outrageous thrill ride. Calling it a “pump and dump”
                    is for marketing purposes only. We will be following the
                    Countdown Crocodile Clock in good faith but make no
                    guarantees about the timing or effect our tax fund will have
                    on the market value of $CROC. This experiment is strictly
                    for fun and the coin has no intrinsic value whatsoever.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;
