import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";

function Buy() {
  const copyAddress = () => {
    const copyText = document.getElementById("myInput");
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      <div className="commanbg d-flex align-items-center">
        <Container className="lbgcontainer">
          <div className="text-center">
            <h1 className="font-weight-bold">How To Buy</h1>
          </div>
          <Row>
            <Col className="">
              <div className="">
                <h3 className="font-weight-bold textgreen">Create a Wallet</h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    Download metamask or your wallet of choice from the app
                    store or google play store FOR FREE. Desktop users, download
                    the google chrome extension by going to https://metamask.io/
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">Get Some ETH</h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    Have ETH in your wallet to switch to $CROC. If you don't
                    have any ETH buy directly on Metamask, transfer from another
                    wallet, or buy on another exchange and send to your wallet.
                  </p>
                </div>
                <h3 className="font-weight-bold textgreen">
                  Smart Contract Address
                </h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    This is like the secret code - enter this to find $CROC.
                    Every NEW COIN has an address like this!
                  </p>
                  <div style={{ fontSize: "1rem" }} className="bigtext aff ">
                    copy this address - &nbsp;
                    <span className="AuthorProfileCard_box_info_address">
                      <input
                        className="ddark"
                        type="text"
                        value={"0x03D6804aDF224804e5226A10584724D7d2149E98"}
                        id="myInput"
                      />
                      &nbsp;
                      <FiCopy onClick={() => copyAddress()} />
                    </span>
                  </div>
                </div>
                <h3 className="font-weight-bold textgreen">Go to Uniswap</h3>
                <div className="mt-3">
                  <p className="home-subtitle">
                    Connect to Uniswap. Go to app.uniswap.org in google chrome
                    or in the browser inside your Metamask app. Switch $ETH for
                    $CROC - done!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Buy;
